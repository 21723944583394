import { isAdvertiser } from '~/helpers'
import { statusType } from '~/modules/retailMedia/components/StatusBar/statusType'

import { formatIntervalDates } from './format'

export const formatResponsePatchCampaign = ({
  currentData,
  updatedData
}: FormatResponsePatchCampaignProps): IndustryCampaignView => ({
  ...currentData,
  raw: { ...currentData.raw, ...updatedData },
  settings: { ...updatedData.settings },
  title: updatedData.name,
  description: updatedData.description,
  status: updatedData.status,
  budget: {
    ...currentData.budget,
    raw: {
      dailyBudget: Number(updatedData?.settings?.daily_budget),
      dailyConsumed: currentData.budget.raw.dailyConsumed
    }
  },
  canEditCampaign:
    statusType?.[updatedData.status]?.canEditDate && isAdvertiser(),
  active: updatedData.active,
  ...formatIntervalDates({
    start_at: updatedData.start_at,
    end_at: updatedData.end_at,
    ignoreUTC: true
  })
})
